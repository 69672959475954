<template>
  <sequential-entrance fromTop>
    <section id="hero">
      <v-row>
        <v-col cols="3" class="pl-8">
          <div class="container-custom pa-8 background">
            <h3 class="font-weight-bold display-0">Survey Categories</h3>

            <v-radio-group class="p-0" dense>
              <v-radio
                class="p-0 text-sm"
                v-for="surveyCategory in surveyCategories"
                :key="surveyCategory.categoryUniqueId"
                :value="surveyCategory.categoryUniqueId"
                @click="
                  filterPublicSurveysByCategory(surveyCategory.categoryUniqueId)
                "
                :label="surveyCategory.categoryName"
              />
            </v-radio-group>
          </div>
        </v-col>
        <v-col cols="9">
          <div class="container-custom pa-8">
            <v-btn translate="3" color="primary" link to="/landing" class="mb-2" medium outlined>
              <span>
                <v-icon color="primary">
                  mdi-arrow-left-circle
                </v-icon>
              </span>
              <span class="primary--text"> GO HOME </span>
            </v-btn>
              <v-card>
                <v-card-title class="primary--text">
                  Public surveys
                </v-card-title>
                <v-card-text v-if="publicSurveys != 0">
                  <v-list nav dense>
                      <v-list-item-group v-model="selectedItem" color="primary">
                        <v-list-item v-for="publicSurvey in publicSurveys"
                          :key="publicSurvey.surveyTitle" 
                          :to="`/response/${publicSurvey.surveyUniqueId}`"
                          style="border-width: 1px; margin: 1px 0 1px 0"
                        >
                          <v-list-item-icon>
                            <v-icon color="primary" size="30">
                              mdi-file-document-outline
                            </v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                            <v-list-item-title>
                              {{ publicSurvey.surveyTitle }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                </v-card-text>

                <v-card-text v-else>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title style="color: rgba(255,0,0,.7)">
                        <v-alert type="error">
                            No surveys or quizes available for this category
                        </v-alert>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-card-text>
              </v-card>
          </div>
        </v-col>
      </v-row>
    </section>
  </sequential-entrance>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      page: 1,
      selectedServices: [],
      dialog: false,
      videoId: "i8IvvHJssWE",
      surveyNumber: "",
    };
  },
  watch: {
    dialog(value) {
      if (!value) {
        this.pause();
      }
    },
  },

  computed: {
    ...mapGetters({
      publicSurveys: "getPublicSurveys",

      isAuthenticated: "isLoggedIn",

      surveyCategories: "getAllSurveyCategories",

      privateSurveyId: "getPrivateSurveyId",
    }),
  },

  methods: {
    filterPublicSurveysByCategory(categoryId) {
      this.$store.dispatch("loadSurveyByCategory", categoryId);
    },
    async findSurveyToRespond(){
      await this.$store.dispatch('fetchSurveyByNumber', this.surveyNumber);
      this.$router.push('/response/'+ this.$store.getters.getPrivateSurveyId)
      this.surveyNumber = ''
    }
  },
  async mounted() {
    await this.$store.dispatch("fetchPublicSurveys");
    await this.$store.dispatch("fetchAllSurveyCategories");
  },
};
</script>

<style lang="scss">
.container-custom {
  background: #fff;
  border-radius: 15px;
  margin-top: 15px;
}
.circle {
  stroke: white;
  stroke-dasharray: 650;
  stroke-dashoffset: 650;
  -webkit-transition: all 0.5s ease-in-out;
  opacity: 0.3;
}

.playBut {
  /*  border: 1px solid red;*/
  display: inline-block;
  -webkit-transition: all 0.5s ease;

  .triangle {
    -webkit-transition: all 0.7s ease-in-out;
    stroke-dasharray: 240;
    stroke-dashoffset: 480;
    stroke: white;
    transform: translateY(0);
  }

  &:hover {
    .triangle {
      stroke-dashoffset: 0;
      opacity: 1;
      stroke: white;
      animation: nudge 0.7s ease-in-out;

      @keyframes nudge {
        0% {
          transform: translateX(0);
        }
        30% {
          transform: translateX(-5px);
        }
        50% {
          transform: translateX(5px);
        }
        70% {
          transform: translateX(-2px);
        }
        100% {
          transform: translateX(0);
        }
      }
    }

    .circle {
      stroke-dashoffset: 0;
      opacity: 1;
    }
  }
}
</style>

<style>
.btn-play {
  transition: 0.2s;
}

.svg-border-waves .v-image {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3rem;
  width: 100%;
  overflow: hidden;
}

#hero {
  z-index: 0;
}
.svg-border-waves img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-bottom: -2px;
  z-index: -1;
}

.card {
  min-height: 300px;
  padding: 10px;
  transition: 0.5s ease-out;
}

.card .v-image {
  margin-bottom: 15px;
  transition: 0.75s;
}

.card h1 {
  margin-bottom: 10px;
}

.zoom-efect {
  transform: scale(1.1);
}

.up {
  transform: translateY(-20px);
  transition: 0.5s ease-out;
}

.background {
  background-image: url("../../assets/mtnew.png");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  height: 900px;
}
section {
  position: relative;
}
</style>












